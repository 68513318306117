export default [
  {
    label: "25%",
    value: "25"
  },
  {
    label: "50%",
    value: "50"
  },
  {
    label: "75%",
    value: "75"
  },
  {
    label: "100%",
    value: "100"
  }
]