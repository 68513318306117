<template>
  <v-chip
    color="white"
    text-color="white"
    class="rounded-xl border-1 bg-ebony-clay-500 mt-2"
    >{{ computedBridgeFee }}</v-chip
  >
</template>

<script setup>
import { useBridgeStore } from "@/store/bridge";
import { storeToRefs } from "pinia";
import { computed } from "vue";
const bridgeStore = useBridgeStore();

const { bridgeFee, wallet } = storeToRefs(bridgeStore);

const computedBridgeFee = computed(() => {
  if (bridgeFee) {
    return `Bridge Fee ${bridgeFee.value} ${wallet.value.symbol || ""}`;
  }
  return `Bridge Fee`;
});
</script>

<style>
</style>