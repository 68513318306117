import passportTokens from '@/constants/passport-tokens.json'

export const chains = [
  {
    chainId: 1,
    networkId: 2415,
    name: 'XODEX',
    bridgeAddress: '0x9485ab64539Af0C819CB76fd9Eb87B1F22728966',
    erc20HandlerAddress: '0x0aaD10f37E03074369786B5AA34e6CaF5d0BB8f0',
    rpcUrl: 'https://xo-dex.io',
    type: 'Ethereum',
    blockExplorer: 'https://explorer.xo-dex.com',
    nativeTokenSymbol: 'XODEX',
    nativeTokenDecimals: 18,
    amplApprovalContract: '0x805c7Ecba41f9321bb098ec1cf31d86d9407de2F',
    amplController: '0x1B228a749077b8e307C5856cE62Ef35d96Dca2ea',
    tokens: passportTokens['XODEX'],
  },
  {
    chainId: 2,
    networkId: 56,
    name: 'BSC',
    bridgeAddress: '0xb2Ea14Fd40869319877b31eA87ea5E767c0342c0',
    erc20HandlerAddress: '0x74a1B0404bd33B44147A55FbF5630436Ca51356e',
    rpcUrl:
      'https://purple-clean-putty.bsc.discover.quiknode.pro/edb81181c10ad577f5d48726d4c79d7ff597997d',
    type: 'Ethereum',
    blockExplorer: 'https://bscscan.com',
    nativeTokenSymbol: 'BNB',
    nativeTokenDecimals: 18,
    amplApprovalContract: '0x17F084dFF8a71e38521BCBD3Da871753Dc67aa81',
    amplController: '0x17F084dFF8a71e38521BCBD3Da871753Dc67aa81',
    tokens: passportTokens['BSC'],
  },
  {
    chainId: 3,
    networkId: 1,
    name: 'Ethereum',
    bridgeAddress: '0x0aad10f37e03074369786b5aa34e6caf5d0bb8f0',
    erc20HandlerAddress: '0x6409e6C39f57D2930EFa9E854D72b0655Aa9cDbC',

    rpcUrl:
      'https://burned-prettiest-river.quiknode.pro/c153000e9318a9b2065946f22ee50f5d3afe3cf3/',
    type: 'Ethereum',
    blockExplorer: 'https://etherscan.io',
    nativeTokenSymbol: 'ETH',
    nativeTokenDecimals: 18,
    amplApprovalContract: '0x805c7Ecba41f9321bb098ec1cf31d86d9407de2F',
    amplController: '0x1B228a749077b8e307C5856cE62Ef35d96Dca2ea',
    tokens: passportTokens['Ethereum'],
  },
]
